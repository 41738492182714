<template>
    <section class="notification-recipients">
        <span class="deq-callout--muted" v-if="!numRecipients">
            No one is subscribed to this notification.
        </span>
        <ul v-if="numRecipients > 0 || editMode">
            <li v-for="recipient in recipientsInAccount" :key="recipient">
                <old-tooltip v-if="isUser(recipient)" label="You are subscribed to this notification">
                    <slotted-tag :disabled="disabled"
                                 close-tooltip="Click to unsubscribe"
                                 @close="$emit('unsubscribe')">
                        <animated-check :animated="justSubscribed"/> Yourself
                    </slotted-tag>

                </old-tooltip>
                <email-tag v-else
                           :email="recipient"
                           close-tooltip="Click to unsubscribe this person"
                           :disabled="disabled || !editable"
                           @close="$emit('remove', recipient)">
                    {{recipient}}
                </email-tag>
            </li>
            <li v-if="editMode">
                <inline-email-input v-model="tempRecipient"
                                    @cancel="cancelEditMode()"
                                    ref="emailEditInput"
                                    @ok="addRecipient($event)"
                                    placeholder="Add recipient email address"
                />
            </li>
        </ul>
        <div class="notification-recipients__buttons">
            <be-button tooltip="Click to add a new recipient"
                       link
                       :disabled="disabled || !editable"
                       @click="enterEditMode()">
                <i class="symbol-add"></i>Add recipient
            </be-button>
            <be-button v-if="!isSubscribed"
                       @click="subscribe()"
                       :disabled="disabled"
                       link
                       tooltip="Click to start receiving this notification" >
                Subscribe
            </be-button>
            <be-button v-else
                       @click="$emit('unsubscribe')"
                       :disabled="disabled"
                       link
                       tooltip="Click to stop receiving this notification">
                Unsubscribe
            </be-button>
        </div>
    </section>

</template>

<script>
import SlottedTag from "../../components/tags/SlottedTag";
import InlineEmailInput from "../../components/inputs/InlineEmailInput";
import BeButton from "../../components/buttons/BeButton";
import EmailTag from "../../components/tags/EmailTag";
import AnimatedCheck from "../../components/animated-icons/AnimatedCheck";
import {mapActions, mapState} from "vuex";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
        name: "NotificationRecipients",
        components: {OldTooltip, AnimatedCheck, EmailTag, BeButton, InlineEmailInput, SlottedTag},

        props: {
            notification: {
                type: Object,
                required: true
            },
            disabled: Boolean,
            editable: Boolean
        },

        data() {
            return {
                editMode: false,
                tempRecipient: '',
                justSubscribed: false,
                recipientsInAccount: []
            }
        },

        computed: {
            ...mapState(['user']),

            numRecipients() {
                return this.recipientsInAccount.length;
            },
            isSubscribed() {
                return this.indexOfLoggedInUser >= 0
            },
            indexOfLoggedInUser() {
                const recipients = this.notification.recipients;
                if (!recipients) return -1;
                const userEmail = this.user.email;
                for (let i = 0; i < recipients.length; i++) {
                    let e = recipients[i].toLowerCase();
                    let j = e.indexOf('<');
                    if (j >= 0) e = e.substring(j + 1);
                    j = e.indexOf('>');
                    if (j >= 0) e = e.substring(0, j);
                    if (userEmail === e) return i;
                }
                return -1;
            }
        },

        watch: {
            'notification.recipients'() {
                this.determineRecipientsInAccount();
            }
        },

        async created() {
            await this.determineRecipientsInAccount();
        },

        methods: {
            ...mapActions('accountUsers', ['refreshAccountUsers', 'getUserByEmail']),

            async determineRecipientsInAccount() {
                await this.refreshAccountUsers();

                if (!this.notification?.recipients) this.recipientsInAccount = [];
                else if (this.user.admin) this.recipientsInAccount = this.notification.recipients ?? [];
                else {
                    const recipients = [];
                    for (const r of this.notification.recipients) {
                        const inAccount = await this.getUserByEmail(r);
                        if (inAccount || r?.toLowerCase()?.indexOf("brandseye") < 0) {
                            recipients.push(r);
                        }
                    }
                    this.recipientsInAccount = recipients;
                }
            },

            subscribe() {
                this.justSubscribed = true;
                this.$emit('subscribe');
            },

            isUser(address) {
                return address.includes(this.user.email);
            },

            enterEditMode(ignoreDisabled) {
                if (this.disabled && !ignoreDisabled) return;
                this.tempRecipient = '';
                this.editMode = true;
                this.$nextTick(() => this.$refs.emailEditInput.focus());
            },

            cancelEditMode() {
                if (this.disabled) return;
                this.editMode = false;
                this.tempRecipient = '';
            },

            addRecipient(enterDown) {
                if (this.disabled || !this.editable) return;
                this.editMode = false;
                if (!this.tempRecipient) return;
                this.$emit('add', this.tempRecipient);
            },
        }
    }
</script>

<style scoped lang="sass">
    .notification-recipients
        color: var(--be-colour-text-dark)

    .notification-recipients__buttons
        display: flex

    ul
        list-style: none
        margin: 0
        padding: 0 0 0 10px
    li
        padding: 3px 0
        line-height: 1.6em
        position: relative
        color: white
        .be-tooltip
            max-width: max-content

    .symbol-checkmark
        color: green
</style>