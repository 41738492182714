<template>
    <slotted-tag :disabled="disabled" :no-close="noClose"
                 :close-tooltip="closeTooltip"
                 :tooltip="tooltip"
                 @click="$emit('click', $event)"
                 @close="$emit('close', $event)">
        <span class="be-email-tag__name">{{name}}</span>
        <span v-if="address" class="be-email-tag__address">&nbsp;&lt;{{address}}&gt;</span>
    </slotted-tag>
</template>

<script>
    import SlottedTag from "./SlottedTag";

    /**
     * Displays our standard tag with email details. Can take either a string
     * of an email address, or our standard BE user object.
     */
    export default {
        name: "EmailTag",
        components: {SlottedTag},

        props: {
            user: Object,
            email: String,
            disabled: Boolean,
            closeTooltip: String,
            tooltip: String,
            noClose: Boolean
        },

        computed: {
            name() {
                const user = this.user;
                if (user) {
                    return `${user.name ?? `${user.firstName} ${user.lastName}`}`.trim();
                }

                const email = this.email;
                if (email) {
                    if (email.indexOf('<') >= 0) {
                        return email.substring(0, email.indexOf('<') - 1).trim();
                    }

                    return email;
                }

                console.error("No user or email supplied");
                return "Unknown"
            },

            address() {
                if (this.user) return this.user.email;

                const email = this.email;
                if (email) {
                    const start = email.indexOf('<');
                    const end = email.indexOf('>');

                    if (start < 0 || end < 0) return null;
                    return email.substring(start + 1, end);
                }

                return "Unknown";
            }
        }
    }
</script>

<style scoped lang="sass">
    .be-email-tag__address
        color: var(--be-colour-muted-text-dark)
</style>