<template>
    <div>
        <inline-text-input v-model.trim="email"
                           ref="input"
                           :placeholder="placeholder"
                           :disabled="disabled"
                           :disable-done="!emailValid"
                           signalForAll
                           ignore-blur
                           @focus="onFocus"
                           @ok="ok($event)"
                           @cancel="$emit('cancel')"/>
        <popup-menu value ref="popup">
            <div class="be-email-input__list mini-menu dark-scrollbars dark-scrollbars--visible">
                <p v-if="!email || visibleAccountUsers.length > 0"
                   class="be-email-intput__instruction">
                    Type in any email address, or choose from the list below.
                </p>
                <p v-else-if="email"
                   class="be-email-intput__instruction">
                    Add an email address for a user not in your account.
                </p>

                <h4 v-if="loadingClients || visibleAccountUsers.length !== 0 || visibleStaff.length === 0">
                    From your account
                </h4>
                <span v-if="loadingClients" class="be-email-input__loading">
                    <spinner-component :size="16"/> <span>Finding your users...</span>
                </span>
                <a v-for="user in visibleAccountUsers"
                   :key="user.id"
                   @click="menuClicked(user)">
                    <span class="be-email-input__name">{{user.name}}</span>
                    <span class="be-email-input__address">&lt;{{user.email}}&gt;</span>
                </a>
                <span v-if="visibleAccountUsers.length === 0 && !loadingClients && users.length > 0 && visibleStaff.length === 0"
                      class="be-email-input__none">
                    No users match your search
                </span>
                <span v-if="users.length === 0 && !loadingClients"
                      class="be-email-input__none">
                    There are no users in your account
                </span>

                <section v-if="user.admin">
                    <h4>DataEQ Staff</h4>
                    <span v-if="loadingStaff" class="be-email-input__loading">
                        <spinner-component :size="16"/> <span>Loading staff...</span>
                    </span>
                    <a v-for="user in visibleStaff"
                       :key="'staff:' + user.id"
                       @click="menuClicked(user)">
                        <span class="be-email-input__name">{{user.name}}</span>
                        <span class="be-email-input__address">&lt;{{user.email}}&gt;</span>
                    </a>
                    <span v-if="visibleStaff.length === 0"
                          class="be-email-input__none">
                        No staff matches your search
                    </span>
                </section>

            </div>
        </popup-menu>
    </div>
</template>

<script>
    import InlineTextInput from "./InlineTextInput";
    import PopupMenu from "../PopupMenu";
    import {mapActions, mapState} from "vuex";
    import SpinnerComponent from "@/components/SpinnerComponent";

    export default {
        name: "InlineEmailInput",
        components: {SpinnerComponent, PopupMenu, InlineTextInput},

        props: {
            value: {
                type: String,
                required: true
            },

            placeholder: String,
            disabled: Boolean
        },

        data() {
            return {
                email: this.value,
                loadingClients: true,
                loadingStaff: true,
                emailValid: false
            }
        },

        watch: {
            email(val) {
                // TLDs change over time. Hardcoding checks here is a bad idea. Email address formats also slowly change.
                this.emailValid = val.includes('@') && val.includes('.');
            }
        },

        computed: {
            ...mapState(['user']),
            ...mapState('staff', ['staff']),
            ...mapState('accountUsers', ['users']),

            visibleAccountUsers() {
                if (!this.email || this.email.length === 0) return this.users;

                const searchEmail = this.email.toLowerCase();
                return this.users
                    .filter(u => {
                        const name = (u.name || '').toLowerCase();
                        const email = (u.email || '').toLowerCase();
                        if (name.includes(searchEmail)) return true;
                        if (email.includes(searchEmail)) return true;
                        return `${name} <${email}>` === searchEmail;
                    });
            },

            visibleStaff() {
                if (!this.email || this.email.length === 0) return this.staff ?? [];
                if (!this.staff) return [];

                const searchEmail = this.email.toLowerCase();
                return this.staff
                    .filter(u => {
                        const name = (u.name || '').toLowerCase();
                        const email = (u.email || '').toLowerCase();
                        if (name.includes(searchEmail)) return true;
                        if (email.includes(searchEmail)) return true;
                        return `${name} <${email}>` === searchEmail;
                    });
            }
        },

        async mounted() {
            if (this.users) {
                this.loadingClients = false;
            }

            await this.refreshAccountUsers();
            this.loadingClients = false;
            await this.refreshStaff();
            this.loadingStaff = false;
        },

        methods: {
            ...mapActions('staff', ['refreshStaff']),
            ...mapActions('accountUsers', ['refreshAccountUsers']),

            menuClicked(user) {
                this.email = `${user.name} <${user.email}>`;
                this.ok();
            },

            ok(enterDown) {
                this.$emit('input', this.email);
                this.$emit('ok', !!enterDown);
            },

            focus() {
                this.$refs.input.focus();
            },

            onFocus() {
                if (this.$refs.popup) this.$refs.popup.showMenu = true;
            }
        }
    }
</script>

<style>

</style>

<style scoped lang="sass">
    .be-email-input__list
        height: 300px
        min-width: 300px
        overflow-y: scroll

    .be-email-input__loading
        display: flex
        align-items: center
        & > *
            padding-left: 10px


    .be-email-input__address
        color: var(--be-colour-muted-text-dark)
        padding-left: 5px

    .be-email-input__none
        font-style: italic
        padding: 0 10px
        color: var(--be-colour-text-dark)
        max-width: 300px
        display: block

    .be-email-intput__instruction
        font-style: italic
        padding: 10px
        margin: 0
        color: var(--be-colour-text-dark)

    h4
        cursor: default
        font-size: 13px
        background: black
        height: 20px
        padding: 5px 10px
        color: white
        margin: 0
        text-transform: uppercase
        font-family: var(--base-font-family)
        position: sticky
        top: 0

    a
        display: block
        color: #fff
        padding: 3px 10px
        cursor: pointer

</style>