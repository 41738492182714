<template>
    <section class="notification-filter-editor"
             :class="{'notification-filter-editor--default': isDefault, 'notification-filter-editor--disabled': disabled}">
        <old-tooltip :label="disabled ? '' : 'Click to edit the filter'">
            <EnglishFilter
                    class="notification-filter-editor__filter"
                    @click="enterEditMode()"
                    :filter="notification.filter"/>
            <i class="symbol-edit" @click="enterEditMode()"></i>
        </old-tooltip>

        <dialog-box v-if="editMode && !isDefault"
                    @close="cancelEditFilter"
                    overlay
                    width="850px"
                    title="What mentions should this notification send to you?">
            <div style="overflow-y: auto; overflow-x: hidden">
                <filter-editor v-model="tempFilter" no-published/>
            </div>
            <template #buttons>
                <section class="notification-filter-editor__filter-buttons">
                    <be-button link @click="cancelEditFilter">Cancel</be-button>
                    <be-button primary @click="updateFilter()">Update</be-button>
                </section>
            </template>
        </dialog-box>
    </section>
</template>

<script>
import EnglishFilter from "../../components/formatters/EnglishFilter";
import DialogBox from "../../components/DialogBox";
import FilterEditor from "../../components/inputs/FilterEditor";
import BeButton from "../../components/buttons/BeButton";
import OldTooltip from "@/components/tooltip/OldTooltip";

export default {
        name: "NotificationFilterEditor",
        components: {OldTooltip, BeButton, FilterEditor, DialogBox, EnglishFilter},

        props: {
            notification: {
                type: Object,
                required: true
            },

            disabled: Boolean
        },

        data() {
            return {
                editMode: false,
                tempFilter: ''
            }
        },

        computed: {
            isDefault() {
                return !!this.notification.externalId;
            }
        },

        methods: {
            enterEditMode() {
                if (this.disabled || this.isDefault) return;
                this.tempFilter = this.notification.filter || '';
                this.editMode = true;
            },

            cancelEditFilter() {
                if (this.disabled) return;
                this.tempFilter = '';
                this.editMode = false;
            },

            updateFilter() {
                if (this.disabled) return;

                const filter = this.tempFilter;
                this.editMode = false;
                this.tempFilter = '';

                this.$emit('update', filter);
            }
        }
    }
</script>

<style scoped lang="sass">

    .notification-filter-editor
        .symbol-edit
            transition-property: opacity, color
            transition-duration: var(--transition-duration)
            opacity: 0
        .notification-filter-editor__filter
            transition: color var(--transition-duration)
            overflow: hidden
            text-overflow: ellipsis
            //noinspection CssInvalidPropertyValue
            display: -webkit-inline-box
            -webkit-box-orient: vertical
            //noinspection CssUnknownProperty
            -webkit-line-clamp: 3
        &:not(.notification-filter-editor--default)
            .symbol-edit
                cursor: pointer
            .notification-filter-editor__filter
                cursor: pointer
            &.notification-filter-editor--disabled
                .symbol-edit
                    cursor: not-allowed
                .notification-filter-editor__filter
                    cursor: not-allowed
        &:not(.notification-filter-editor--default):hover
            .symbol-edit
                opacity: 1
                color: var(--be-colour-text-dark__hover)
            .notification-filter-editor__filter
                color: var(--be-colour-text-dark__hover)

    .notification-filter-editor__filter-buttons
        display: flex
        justify-content: flex-end



</style>