<template>
    <old-tooltip class="notification-saving"
             label="We're saving the changes to your notification">
        <spinner :size="16"/>
        <span class="notification-saving__message">Saving…</span>
    </old-tooltip>
</template>


<script>
    import Spinner from "../../components/SpinnerComponent";
    import Tooltip from "../../components/tooltip/OldTooltip";
    import OldTooltip from "@/components/tooltip/OldTooltip";
    export default {
        components: {OldTooltip, Spinner}
    }
</script>

<style scoped lang="sass">
    .notification-saving
        display: inline-flex
        align-items: baseline
        font-size: 1rem
        width: max-content
        color: var(--be-colour-muted-text-dark)
        & ::v-deep .css-spinner
            top: 3px
            margin-left: 5px
        .notification-saving__message
            padding-left: 5px

</style>